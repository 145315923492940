import Vue from 'vue';

function reportError(error) {
  if (this.$honeybadger) {
    Vue.$honeybadger.notify(error);
  } else {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default {
  install() {
    Vue.reportError = reportError;
    Vue.prototype.$reportError = reportError;
  },
};
