import Vue from 'vue';
import HoneybadgerVue from '@honeybadger-io/vue';
import errorReporting from '../utils/error_reporting';

if (process.env.RAILS_ENV === 'production' || process.env.RAILS_ENV === 'staging') {
  Vue.use(HoneybadgerVue, {
    apiKey: process.env.HONEYBADGER_API_KEY_JS,
    environment: process.env.RAILS_ENV,
    revision: process.env.SOURCE_VERSION,
    enableUnhandledRejection: false,
  });
}

Vue.use(errorReporting, {});
